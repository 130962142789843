<template>
  <a-layout class='s_bf'>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input-group compact style="width:300px">
            <a-select v-model:value="search.key_type" style="width: 30%">
              <a-select-option value="1">激活码</a-select-option>
              <a-select-option value="2">所属品牌</a-select-option>
            </a-select>
            <a-input style="width: 70%" v-model:value="search.keywords" placeholder="请输入关键词" />
          </a-input-group>
        </a-form-item>
        <a-form-item label="操作时间" name='time'>
          <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item label="创建时间" name='time'>
          <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onCreateTimeChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-layout>
</template>
<script>
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message, Modal } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { deviceLogs } from "@/api/pda.js";
// 初始化默认筛选项数值
let defSearch = {
  key_type: "1",
  keywords: "",
  start_time: "",
  end_time: "",
  create_start_time: "",
  create_end_time: "",
  page: 1,
  limit: 5,
};
export default {
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      total:0
    });
    let columns = [
      {
        title: "所属品牌",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "激活码",
        dataIndex: "active_code",
        key: "active_code",
      },
      {
        title: "创建时间",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "设备号",
        dataIndex: "imei",
        key: "imei",
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "status_text",
      },
      {
        title: "绑定时间",
        dataIndex: "updated_at",
        key: "updated_at",
      },
      {
        title: "操作内容",
        dataIndex: "status_text",
        key: "status_text",
      },
    ];
    // 时间筛选
    const onChange = (e) => {
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
    // 时间筛选
    const onCreateTimeChange = (e) => {
      if (e.length > 0) {
        search.value.create_start_time = e[0];
        search.value.create_end_time = e[1];
      } else {
        search.value.create_start_time = "";
        search.value.create_end_time = "";
      }
    };
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };

    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await deviceLogs(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    onMounted(() => {
      initData(search.value);
    });

    return {
      ...toRefs(state),
      search,
      data,
      columns,
      onCreateTimeChange,
      onChange,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
    };
  },
};
</script>
<style>
.tis {
  color: #f5222d;
}
</style>